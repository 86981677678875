import cn from "classnames"
import s from "./Frontpage.module.scss"


export default function Frontpage(): JSX.Element {


  return (
    <div className={cn(s["frontpage"], "mt-xl")}>
      {/* <div className="text-center mb-medium" style={{ fontSize: "1.2em", fontWeight: "600" }}>Vi søger på tværs af markedets varer, og via AI-analyse guider vi til indkøb med lavt klimaaftryk.</div> */}
      {/* <div className="edge-to-edge-mobile hide-scrollbar" style={{ overflow: "scroll hidden", paddingLeft: "var(--large)", marginBottom: "50px", textAlign: "center" }}>
        <img className="hide-on-mobile" style={{ "maxWidth": "none", "height": "68px", paddingRight: "12px" }} src="/images/store-logo-ribbon.png" alt="Butikker som Dagly understøtter" />
        <img className="hide-on-desktop" style={{ "maxWidth": "none", "height": "58px", paddingRight: "12px" }} src="/images/store-logo-ribbon.png" alt="Butikker som Dagly understøtter" />
      </div> */}
      <div className="hide-on-mobile" style={{ height: "0px", marginBottom: "120px" }}></div>
      <div style={{ maxWidth: "600px", margin: "0 auto" }}>
        <h2>Test Daglys beta-version og se, hvordan du sparer på dine og klodens ressourcer.</h2>
        <p>Dine daglige indkøb har stor betydning for klimaet og for din økonomi. Vi finder hele din indkøbsliste på tværs af markedet, og guider til hvor denne kan købes mest klimavenlig og til den bedste pris.</p>
        <p>Da Dagly stadig udvikles og testes, er hjemmesidens data ikke opdateret, og resultater og vareinformation kan derfor ikke anses som retvisende endnu.</p>
      </div>
      {/* <img className="hide-on-desktop" src="/images/dagly app teaser.png" /> */}
      <div className="hide-on-mobile" style={{ height: "0px", marginBottom: "120px" }}></div>
      <h2 className="mt-xxl mb-medium hide-on-desktop">Så nemt er det at bruge Dagly.</h2>
      <h2 className="mt-xxl mb-large hide-on-mobile">Så nemt er det at bruge Dagly.</h2>
      <div className={cn(s["tutorial"])}>
        <div>
          <img src="/images/dagly-tutorial-1.png" />
          <span>
            <strong>Tilføj dagligvarer.</strong>
            Søg nemt efter dine dagligvarer, og tilføj dem til din indkøbsliste. Enten som helt specifikt produkt eller som varekategori.
          </span>
        </div>
        <div>
          <img src="/images/dagly-tutorial-2.png" />
          <span>
            <strong>Se resultater.</strong>
            Vi søger og viser, hvor din indkøbsliste kan købes mest klimavenlig og til den bedste pris.
          </span>
        </div>
        <div>
          <img src="/images/dagly-tutorial-3.png" />
          <span><strong>Tilret præferencer.</strong>
            Søgningen opdateres og vi husker dine valg til næste gang.</span>
        </div>
        <div>
          <img src="/images/dagly-tutorial-4.png" />
          <span>
            <strong>Se varers klimaaftryk.</strong>
            Beskrivelser af de enkelte produkters estimerede klimaaftryk.
          </span>
        </div>

      </div>
      <div className="hide-on-mobile" style={{ height: "0px", marginBottom: "120px" }}></div>
      {/* <div style={{ maxWidth: "600px", margin: "0 auto" }}>
        <h4 className="mt-xxl">Automatiske indkøbslister, ugens billige retter og brug af AI.</h4>
        <p>Vi arbejdet løbende på at forbedre oplevelsen af Dagly. De nævnte features er blot nogle af de mange tilføjelser, som venter lige rundt om hjørnet.</p>
      </div> */}

      <div className="edge-to-edge-mobile disclaimer">
        <div className="logo-container" style={{ display: "flex", marginBottom: "36px" }}><img style={{ width: "120px" }} src="/dagly-logo-beta.svg" alt="" /></div>

        <div className="" style={{ color: "black", opacity: 0.7, maxWidth: "600px", margin: "0 auto" }}>
          <p>Tak fordi du tester Daglys beta-version, for at se, hvordan du kan nedbringe dit klimaaftryk, når du handler dagligvarer, og samtidig spare på pengene.</p>
          <p>Vores vision er at skabe en ny og forbedret oplevelse af de daglige indkøb sammen med dagligvarekæderne, så du kan handle ind med god samvittighed over for både din økonomi og klimaet.</p>
          <p>OBS: Det er vigtigt at nævne, at alle data (herunder varers informationer og priser) på Daglys beta-version ikke er opdaterede og derfor ikke må anses som retvisende endnu. </p>
          <p>Dagly bruger kunstig intelligens til at estimere klimaaftryk på markedets mange tusinde varer, og vores søgemaskine og algoritme må derfor ikke anses som 100% sandfærdig information. Vi tager derfor forbehold for eventuelle fejlvurderinger af produkters klimaaftryk. </p>
          <p>Vi arbejder løbende på at forfine vores søgemaskine og måden vi bruger kunstig intelligens, men kommer løbende også til at inddrage producenternes egne klimarapporter og mærkninger i vores vurderinger.</p>
          <p>Vil du gerne kontakte os, så skriv til os på <a href="mailto:kontakt@dagly.dk">kontakt@dagly.dk</a>.</p>


          <p>All rights reserved – Dagly.dk – {new Date().getFullYear()}</p>
        </div>
      </div>

    </div>
  )
}