import cn from "classnames"
import s from "./Header.module.scss"

export default function Header(): JSX.Element {
  return (
    <nav className={cn(s.header)}>
      <div className="container">
        <div className={s["header__logo"]}>
          <a href="/"><img src="/dagly-logo-beta.svg" alt="" /></a>
        </div>

        <div className={s["header__menu"]}>
          {/* <span><img src="/icons/user.svg" alt="" /></span> */}
          {/* <span><img src="/icons/hamburger-menu.svg" alt="" /></span> */}
        </div>
      </div>
    </nav>
  )
}